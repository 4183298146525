@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');

:root {
  --color-secondary2: #F5D51E;
  --color-secondary: #ffff00;
  --color-primary2: #4370ab;
  --color-primary: #0e2841;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins, Helvetica, Arial, Lucida, sans-serif';
}

body {
  margin: 0;
  font-family: 'Questrial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  font-family: 'Moderniz', sans-serif;
}	

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
